<!--  Footer section  -->
<template>
    <div class="footer">
        <div class="footer-section md:p-16 p-10 bg-hensa-form flex md:flex-row flex-col  md:space-x-14 md:space-y-0 space-y-10">
            <!-- Logo section -->
            <div class="flex-one md:w-1/4 w-full">
                <a class="block" href="/">
                    <img class="h-16" src="../../assets/images/webp/logo.webp"/>
                </a>
                <div class="social-section mt-10 flex space-x-8">
                    <div class="flex-one">
                        <span class="text-grey-principal">{{  $t('topHeader.tel') }}</span><br/>
                        <span class="text-blue-principal font-medium">+221 91394545</span>
                    </div>
                      <div class="flex-one">
                        <span class="text-grey-principal ">{{  $t('topHeader.email') }}</span><br/>
                        <span class="text-blue-principal font-medium">info@eclipse.holding</span>
                    </div>

                </div>
            </div>
            
            <!-- formation section -->
            <div class="md:w-1/4 w-full">
                <div
                @click="openFormation = !openFormation" 
                class="flex items-center cursor-pointer text-blue-principal font-bold md:text-x22 text-xl"> {{ $t('footer.training') }} 
                <span :class="[
                    'ml-10 transform',
                    openFormation ? 'rotate-180' : 'rotate-0'
                ]"> <icon :data="ic_arrow" height="18" width="18" original/>  </span> 
                </div> 
                <div v-if="openFormation" class="content-list flex flex-col space-y-3 mt-4">
                    <span @click="goDetail('digitalProjectManager')" class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.digitalProjectManager') }} </span>
                    <span @click="goDetail('webDevelopper')"  class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.webDevelopper') }} </span>
                    <span @click="goDetail('ecom')" class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.ecom') }} </span>
                    <span @click="goDetail('itTech')"  class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.itTech').split('(')[1].replace(')', '') }} </span>
                    <span @click="goDetail('twin')"  class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.twin').split('(')[1].replace(')', '') }} </span>
                    <span @click="goDetail('srit')"  class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.srit').split('(')[1].replace(')', '') }} </span>
                    <span @click="goDetail('bfa')" class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.bfa') }} </span>
                    <span @click="goDetail('audit')"  class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.audit') }} </span>
                    <span @click="goDetail('projectManagement')"  class="cursor-pointer inline-block text-grey-principal"> {{ $t('training.projectManagement') }} </span>
                </div>
            </div>

            <!-- Admission section -->
            <div class="md:w-1/4 w-full">
                <div 
                 @click="openAdmission = !openAdmission" 
                class="flex items-center cursor-pointer text-blue-principal font-bold md:text-x22 text-xl"> {{ $t('footer.admission') }} 
                <span 
                :class="[
                    'ml-10 transform',
                    openAdmission ? 'rotate-180' : 'rotate-0'
                ]"> <icon :data="ic_arrow" height="18" width="18" original/>  </span> 
                </div> 
                 <div 
                 v-if="openAdmission"
                 class="content-list flex flex-col space-y-3 mt-4">
                    <a href="/inscription" class=" text-grey-principal"> {{ $t('button.candidateNow') }} </a>
                    <span @click="showSchoOption" class="text-grey-principal cursor-pointer inline-block"> {{ $t('button.candidateDocument') }} </span>
                    <a href="https://firebasestorage.googleapis.com/v0/b/rema-da2f4.appspot.com/o/brochure.pdf?alt=media&token=bf685015-b301-48e0-babf-0de37515cee5" class="text-grey-principal" target="_blank"> {{ $t('button.brochure') }} </a>
                </div>
            </div>

            <!-- Copyright And Social -->
            <div class="copyright md:shrink">
             <div class="flex md:space-x-8 space-y-8 md:space-y-0 items-center md:flex-row flex-col ">
                  <div class="flex cursor-pointer text-blue-light font-semibold text-sm">
                     <span>{{ $t('copyright') }} </span>
                </div>
                <div class="social-icon flex space-x-4">
                      <a 
                      target="_blank"
                      href="https://www.facebook.com"
                      class="h-12 w-12 flex items-center justify-center cursor-pointer rounded-full bg-red-principal">
                        <icon :data="ic_facebook" height="18" width="18" original/> 
                      </a>
                    <a
                     href="https://twitter.com/" target="_blank" class="h-12 w-12 flex items-center justify-center rounded-full bg-red-principal">
                         <icon :data="ic_twitter" height="18" width="18" original/> 
                    </a>
                     <a class="h-12 w-12 flex items-center justify-center rounded-full bg-red-principal">
                         <icon :data="ic_linkedin" height="18" width="18" original/> 
                     </a>
                </div>
             </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ic_arrow from '../../assets/icons/ic_drop_arrow.svg'
import ic_facebook from '../../assets/icons/ic_facebook.svg'
import ic_twitter from '../../assets/icons/ic_twitter.svg'
import ic_linkedin from '../../assets/icons/ic_linkedin.svg'
import { ref, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
 
const router = useRouter()
const store = useStore();

const emit = defineEmits(['shoWOption'])

function showSchoOption() {
    emit('shoWOption', true)
}

const openFormation = ref(false)
const openAdmission = ref(false)


function goDetail(item) {
   store.dispatch('saveTrainning', item)
   if (router.currentRoute.path === '/details') {
    router.push('details')
   } else {
    router.go(router.currentRoute)
   }
}
</script>