<template>
  <div 
  @click="closePopup"
  class="fixed h-screen w-screen bg-black-principal z-50 bg-opacity-20 flex items-center justify-center">
    <div class="bg-white p-8 rounded-xl shadow-xl flex gap-12 items-center justify-center">
        <a href="https://firebasestorage.googleapis.com/v0/b/rema-da2f4.appspot.com/o/benin.pdf?alt=media&token=795c0856-f088-447c-9a4c-1040bda57eec" class="block" target="_blank">
            <div class="flex items-center justify-center flex-col">
                <icon :data="ic_benin" height="58" width="58" original/> 
                <h3 class="mt-3">{{ $t('benin') }}</h3>
            </div>
        </a>
        <a href="https://firebasestorage.googleapis.com/v0/b/rema-da2f4.appspot.com/o/french.pdf?alt=media&token=403134ea-d586-4cd5-94bf-8f6146ac39d6" class="block" target="_blank">
            <div class="flex items-center justify-center flex-col">
                <icon :data="ic_france" height="58" width="58" original/> 
                <h3 class="mt-3">{{ $t('french') }}</h3>
            </div>
        </a>
    </div>
  </div>
</template>

<script setup>
import ic_benin from '../../assets/icons/ic_benin.svg'
import ic_france from '../../assets/icons/ic_france.svg'
import { defineEmits } from 'vue';


const emit = defineEmits(['close']);

function closePopup() {
    emit('close', true)
}

</script>

<style>

</style>